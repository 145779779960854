<template>
  <div>
    <iframe
      width="100%"
      height="1000px"
      :src="
        isFranchiseOrHigher
          ? 'https://lookerstudio.google.com/embed/reporting/4b7000eb-dd96-47ee-9c74-5c48e290b845/page/p_6yaj5ywu3c'
          : 'https://lookerstudio.google.com/embed/reporting/03c6fc54-87ef-4efe-b520-13697445540b/page/p_6yaj5ywu3c'
      "
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isNotCommonUser } from "@/constants/consultants";
import * as accountTypes from "@/modules/account/store/types";
export default {
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
    isFranchiseOrHigher() {
      return isNotCommonUser(this.user?.user_role_id);
    },
  },
};
</script>
